import * as React from "react"
import { useEffect } from "react"
import { graphql } from "gatsby"

import Seo from "../components/seo"

const Presentation = ({ data, location }) => {
  useEffect(() => {
    // force reload, (cached?) resources often don't load successfully on first page view
    if (! window.location.hash) {
      setTimeout(() => {
        window.location = window.location + '#loaded'
        window.location.reload()
      }, 1750)
    } else if ("pushState" in window.history) {
      window.history.pushState("", document.title, window.location.pathname + window.location.search)
    }
  }, [])
  return (
    <>
      <div className="creator-studio"><iframe title="presentation" src="https://talltweets.com/slidecast/?v=aCadmiIfbcI&s=1oHEBhm1J8uYuXYjjoiyDOmq-U47EeGl_wwQCCpnbHww&m=0,46,67,130,198,306,318,352,387,413,471,506,533,564,598,611,617,635,654,670,691,717,749,799,816,845,867,889,932,942,968,1007,1022,1031,1048,1066,1096,1123,1142,1151,1171,1192,1253,1321,1330,1391,1417,1459,1563,1570,1621,1646,1678,1715,1729,1761,1786,1847,1934,1945,2015,2036,2085,2131,2188,2246,2271,2323,2355,2422,2525,2608"></iframe></div>
      <div className="below-studio">
        <ul>
          <li>Discussion <a target="_blank" rel="noreferrer" href="https://news.ycombinator.com/item?id=33475391">on Hacker News</a></li>
          <li>Discussion <a target="_blank" rel="noreferrer" href="https://old.reddit.com/r/FreeSpeech/duplicates/yc8whv/revedditcom_improving_online_discourse_with/">on Reddit</a></li>
          <li>Twitter <a target="_blank" rel="noreferrer" href="https://twitter.com/rhaksw">@rhaksw</a></li>
          <li>Download the <a target="_blank" rel="noreferrer" href="https://drive.google.com/file/d/1IYAI8ZG-xp7PXlFJrsgo3Am2L10BjLNc/view?usp=sharing">slides</a></li>
        </ul>
      </div>
    </>
  )
}

export const Head = ({data}) => {
  return (
    <Seo
        description="A talk given for Truth and Trust Online on October 12th, 2022."
        imagepath='/talk-screenshot-1-1-ratio.png'
        title="Reveddit.com: Improving online discourse with transparent moderation. October 12, 2022"
    />
  )
}

export default Presentation
export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
